@use '../utils/breakpoint';
@use '../utils/size';

.map {
    line-height: 0;
    overflow: hidden;
    iframe {
        border: none;
        width: 100%;
    }
    
    @include breakpoint.break-under(tablet) {
        iframe {
            height: breakpoint.xdMbToRate(size.$map-height-mb);
        }
    }

    @include breakpoint.break-over(tablet) {
        iframe {
            height: breakpoint.xdPcToRate(size.$map-height-pc);
        }
    }
}