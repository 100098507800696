@use '../utils/breakpoint';
@use '../utils/size';
@use '../utils/text';

.natulary {
    .catch {
        &--picture {
            margin: 0 auto;
            img {
                width: 100%;
                height: auto;
            }
        }
        .copy {
            margin: 0 auto;
            &::before,
            &::after {
                content: "";
                display: block;
                width: 100%;
                background: url(/./assets/images/naturaly/catch-border.png) no-repeat center center;
                background-size: cover;
            }
            &--text {
                text-align: left;
            }
        }
    }

    .offer {
        text-align: center;
        p {
            margin-left: auto;
            margin-right: auto;
        }
        img {
            width: 100%;
            height: auto;
        }
    }

    @include breakpoint.break-under(tablet) {
        padding-top: breakpoint.xdMbToRate(size.$natulary-spacer-t-mb);
        .section--content {
            padding: 0 breakpoint.xdMbToRate(size.$natulary-catch-spacer-x-mb);
        }
        .catch {
            margin-bottom: breakpoint.xdMbToRate(size.$natulary-catch-marginBottom-mb);
            &--picture {
                margin-bottom: breakpoint.xdMbToRate(size.$natulary-catch-img-marginBottom-mb);
            }
            .copy {
                &::before,
                &::after {
                    height: breakpoint.xdMbToRate(size.$natulary-catch-text-decoration-height-mb);
                }
                &--text {
                    padding-left: breakpoint.xdMbToRate(size.$natulary-catch-text-spacer-x-mb);
                    padding-right: breakpoint.xdMbToRate(size.$natulary-catch-text-spacer-x-mb);
                    padding-top: breakpoint.xdMbToRate(size.$natulary-catch-text-spacer-y-mb);
                    padding-bottom: breakpoint.xdMbToRate(size.$natulary-catch-text-spacer-y-mb);
                    font-size: breakpoint.xdMbToRate(size.$natulary-catch-text-fontsize-mb);
                    @include text.lineHeight(size.$natulary-catch-text-fontsize-mb, size.$natulary-catch-text-lineheight-mb);
                }
            }
        }
        .offer {
            &--free {
                width: breakpoint.xdMbToRate(size.$natulary-offer-free-img-width-mb);
                margin-bottom: breakpoint.xdMbToRate(size.$natulary-offer-free-marginBottom-mb);
            }
            &--training {
                width: breakpoint.xdMbToRate(size.$natulary-offer-training-img-width-mb);
            }
        }
    }
    @include breakpoint.break-over(tablet) {
        padding-top: breakpoint.xdPcToRate(size.$natulary-spacer-t-pc);
        .section--content {
            width: breakpoint.xdPcToRate(size.$natulary-catch-width-pc);
        }
        .catch {
            margin-bottom: breakpoint.xdPcToRate(size.$natulary-catch-marginBottom-pc);
            width: breakpoint.xdPcToRate(size.$natulary-catch-width-pc);
            &--picture {
                margin-bottom: breakpoint.xdPcToRate(size.$natulary-catch-img-marginBotton-pc);
                width: breakpoint.xdPcToRate(size.$natulary-catch-img-width-pc);
            }
            .copy {
                &::before,
                &::after {
                    height: breakpoint.xdPcToRate(size.$natulary-catch-text-decoration-height-pc);
                }
                &--text {
                    margin-left: breakpoint.xdPcToRate(size.$natulary-catch-text-spacer-l-pc);
                    padding-top: breakpoint.xdPcToRate(size.$natulary-catch-text-spacer-y-px);
                    padding-bottom: breakpoint.xdPcToRate(size.$natulary-catch-text-spacer-y-px);
                    font-size: breakpoint.xdPcToRate(size.$natulary-catch-text-fontsize-pc);
                    @include text.lineHeight(size.$natulary-catch-text-fontsize-pc, size.$natulary-catch-text-lineheight-pc);
                }
            }
        }
        
        .offer {
            &--free {
                width: breakpoint.xdPcToRate(size.$natulary-offer-free-img-width-pc);
                margin-bottom: breakpoint.xdPcToRate(size.$natulary-offer-free-marginBottom-pc);
            }
            &--training {
                width: breakpoint.xdPcToRate(size.$natulary-offer-training-img-width-pc);
            }
        }
    }
}