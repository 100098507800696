@use '../utils/size';
@use '../utils/breakpoint';
@use '../utils/text';

.firstOffer {
    .price {
        margin-left: auto;
        margin-right: auto;
        img {
            width: 100%;
            height: auto;
        }
    }
    .text {
        h2 {
            font-weight: bold;
            @include text.fontYuGothicBold();
        }
        p {
            text-align: left;
            @include text.fontYuMinchoMedium();
        }
    }

    @include breakpoint.break-under(tablet) {
        .price {
            width: breakpoint.xdMbToRate(size.$firstOffer-price-img-width-mb);
            margin-bottom: breakpoint.xdMbToRate(size.$firstOffer-price-img-marginBottom-mb);
        }
        .text {
            margin-left: breakpoint.xdMbToRate(size.$firstOffer-spacer-x-mb);
            margin-right: breakpoint.xdMbToRate(size.$firstOffer-spacer-x-mb);
            h2 {
                font-size: breakpoint.xdMbToRate(size.$firstOffer-h2-fontsize-mb);
                margin-bottom: breakpoint.xdMbToRate(size.$firstOffer-h2-marginBottom-mb);
            }
            p {
                font-size: breakpoint.xdMbToRate(size.$firstOffer-text-fontsize-mb);
                @include text.lineHeight(size.$firstOffer-text-fontsize-mb, size.$firstOffer-text-lineheight-mb);
            }
        }
    }
    @include breakpoint.break-over(tablet) {
        .price {
            width: breakpoint.xdPcToRate(size.$firstOffer-price-img-width-pc);
            margin-bottom: breakpoint.xdPcToRate(size.$firstOffer-price-img-marginBottom-pc);
        }
        .text {
            margin-left: breakpoint.xdPcToRate(size.$firstOffer-spacer-l-pc);
            margin-right: breakpoint.xdPcToRate(size.$firstOffer-spacer-r-pc);
            h2 {
                font-size: breakpoint.xdPcToRate(size.$firstOffer-h2-fontsize-pc);
                margin-bottom: breakpoint.xdPcToRate(size.$firstOffer-h2-marginBottom-pc);
            }
            p {
                font-size: breakpoint.xdPcToRate(size.$firstOffer-text-fontsize-pc);
                @include text.lineHeight(size.$firstOffer-text-fontsize-pc, size.$firstOffer-text-lineheight-pc);
            }
        }
    }
}