@use '../utils/breakpoint';
@use '../utils/size';
@use '../utils/text';
@use '../utils/color';

.course {
    &--logo {
        margin-left: auto;
        margin-right: auto;
        img {
            width: 100%;
            height: auto;
        }
    }
    &--title {
        font-weight: bold;
        line-height: 1;
    }
    &--subtitle {
        line-height: 1;
        text-indent: .5rem;
    }

    .menu {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        display: flex;
        align-items: stretch;
        &--text {
            background: rgba(2, 2, 2, .82);
            h3 {
                font-weight: bold;
                color: color.$text-yellow-light;
                line-height: 1;
            }
            p {
                color: #fff;
                font-weight: bold;
                text-align: left;
            }
        }
    }

    @include breakpoint.break-under(tablet) {
        &--logo {
            margin-bottom: breakpoint.xdMbToRate(size.$course-logo-marginBottom-mb);
            width: breakpoint.xdMbToRate(size.$course-logo-width-mb);
        }
        &--title {
            font-size: breakpoint.xdMbToRate(size.$course-title-fontsize-mb);
            margin-bottom: breakpoint.xdMbToRate(size.$course-title-marginBottom-mb);
        }
        &--subtitle {
            font-size: breakpoint.xdMbToRate(size.$course-subtitle-fontsize-mb);
            margin-bottom: breakpoint.xdMbToRate(size.$course-subtitle-marginBottom-mb);
        }

        // menu
        .menu {
            padding-top: breakpoint.xdMbToRate(size.$course-menu-spacer-y-mb);
            padding-bottom: breakpoint.xdMbToRate(size.$course-menu-spacer-y-mb);
            padding-left: breakpoint.xdMbToRate(size.$course-menu-spacer-x-mb);
            padding-right: breakpoint.xdMbToRate(size.$course-menu-spacer-x-mb);
            &--text {
                padding-top: breakpoint.xdMbToRate(size.$course-menu-text-spacer-t-mb);
                padding-bottom: breakpoint.xdMbToRate(size.$course-menu-text-spacer-b-mb);
                h3 {
                    font-size: breakpoint.xdMbToRate(size.$course-menu-title-fontsize-mb);
                    margin-bottom: breakpoint.xdMbToRate(size.$course-menu-title-marginBottom-mb);
                }
                p {
                    margin-left: breakpoint.xdMbToRate(size.$course-menu-text-spacer-l-mb);
                    margin-right: breakpoint.xdMbToRate(size.$course-menu-text-spacer-r-mb);
                    font-size: breakpoint.xdMbToRate(size.$course-menu-text-fontsize-mb);
                    @include text.lineHeight(size.$course-menu-text-fontsize-mb, size.$course-menu-text-lineheight-mb);
                }
            }
            &--counseling {
                background-image: url(/./assets/images/course/menu-counseling-bg-mb.png);
            }
            &--personal {
                background-image: url(/./assets/images/course/menu-personal-bg-mb.png);
            }
            &--kick {
                background-image: url(/./assets/images/course/menu-kick-bg-mb.png);
            }
            &--feedback {
                background-image: url(/./assets/images/course/menu-feedback-bg-mb.png);
            }
            &--stretch {
                background-image: url(/./assets/images/course/menu-stretch-bg-mb.png);
            }
            &--advice {
                background-image: url(/./assets/images/course/menu-advice-bg-mb.png);
            }
            &--included {
                background-image: url(/./assets/images/course/menu-included-bg-mb.png);
            }
        }
        .others {
            display: none;
        }
    }

    @include breakpoint.break-over(tablet) {
        &--logo {
            margin-bottom: breakpoint.xdPcToRate(size.$course-logo-marginBottom-pc);
            width: breakpoint.xdPcToRate(size.$course-logo-width-pc);
        }
        &--title {
            font-size: breakpoint.xdPcToRate(size.$course-title-fontsize-pc);
            margin-bottom: breakpoint.xdPcToRate(size.$course-title-marginBottom-pc);
        }
        &--subtitle {
            font-size: breakpoint.xdPcToRate(size.$course-subtitle-fontsize-pc);
            margin-bottom: breakpoint.xdPcToRate(size.$course-subtitle-marginBottom-pc);
        }

        // menu
        .menus {
            display: flex;
            flex-wrap: wrap;
            gap: 0;
            .menu {
                width: 50%;
                box-sizing: border-box;
            }
        }
        .menu {
            padding-top: breakpoint.xdPcToRate(size.$course-menu-spacer-y-pc);
            padding-bottom: breakpoint.xdPcToRate(size.$course-menu-spacer-y-pc);
            padding-left: breakpoint.xdPcToRate(size.$course-menu-spacer-x-pc);
            padding-right: breakpoint.xdPcToRate(size.$course-menu-spacer-x-pc);
            &--text {
                padding-top: breakpoint.xdPcToRate(size.$course-menu-text-spacer-t-pc);
                padding-bottom: breakpoint.xdPcToRate(size.$course-menu-text-spacer-b-pc);
                h3 {
                    margin-bottom: breakpoint.xdPcToRate(size.$course-menu-title-marginBottom-pc);
                    font-size: breakpoint.xdPcToRate(size.$course-menu-title-fontsize-pc);
                }
                p {
                    margin-left: breakpoint.xdPcToRate(size.$course-menu-text-spacer-l-pc);
                    margin-right: breakpoint.xdPcToRate(size.$course-menu-text-spacer-r-pc);
                    margin-bottom: 0;
                    font-size: breakpoint.xdPcToRate(size.$course-menu-text-fontsize-pc);
                    @include text.lineHeight(size.$course-menu-text-fontsize-pc, size.$course-menu-text-lineheight-pc);
                }
                .line {
                    display: inline-block;
                }
                .hide {
                    display: none;
                }
            }
            &--counseling {
                background-image: url(/./assets/images/course/menu-counseling-bg-pc.png);
            }
            &--personal {
                background-image: url(/./assets/images/course/menu-personal-bg-pc.png);
            }
            &--kick {
                background-image: url(/./assets/images/course/menu-kick-bg-pc.png);
            }
            &--feedback {
                background-image: url(/./assets/images/course/menu-feedback-bg-pc.png);
            }
            &--stretch {
                background-image: url(/./assets/images/course/menu-stretch-bg-pc.png);
            }
            &--advice {
                background-imagE: url(/./assets/images/course/menu-advice-bg-pc.png);
            }
            &--included {
                background-image: url(/./assets/images/course/menu-included-bg-pc.png);
            }
        }
        .others {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-grow: 1;
            p {
                color: color.$text-yellow-light;
                font-size: breakpoint.xdPcToRate(size.$course-others-text-fontsize-pc);
                font-weight: bold;
            }
        }
    }
}