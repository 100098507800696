@use './utils/breakpoint';
@use './utils/size';
@use './utils/text';

* {
    margin: 0;
    padding: 0;
}

html {
    font-size: 62.5%;
}
body {
    font-size: 16px;
    font-size: 1.6rem;
    background: #000;
    color: #fff;
    @include text.fontSansSerif();
}

img {
    height: auto;
}

.section {
    text-align: center;
    box-sizing: border-box;
    &--content {
        margin: 0 auto;
    }
}

@include breakpoint.break-under(tablet) {
    .natulary {
        margin-bottom: breakpoint.xdMbToRate(size.$natulary-marginBottom-mb);
    }
    .firstOffer {
        margin-bottom: breakpoint.xdMbToRate(size.$firstOffer-marginBottom-mb);
    }
    .try {
        margin-bottom: breakpoint.xdMbToRate(size.$try-marginButton-mb);
    }
    .about {
        margin-bottom: breakpoint.xdMbToRate(size.$about-marginBottom-mb);
    }
    .course {
        margin-bottom: breakpoint.xdMbToRate(size.$course-marginBottom-mb);
    }
}

@include breakpoint.break-over(tablet) {
    .natulary {
        margin-bottom: breakpoint.xdPcToRate(size.$natulary-marginBottom-pc);
    }
    .firstOffer {
        margin-bottom: breakpoint.xdPcToRate(size.$firstOffer-marginBottom-pc);
    }
    .try {
        margin-bottom: breakpoint.xdPcToRate(size.$try-marginButton-pc);
    }
    .about {
        margin-bottom: breakpoint.xdPcToRate(size.$about-marginBottom-pc);
    }
    .course {
        margin-bottom: breakpoint.xdPcToRate(size.$course-marginBottom-pc);
    }
}