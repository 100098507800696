@use '../utils/breakpoint';
@use '../utils/size';
@use '../utils/text';

.try {
    .visual {
        img {
            width: 100%;
            height: auto;
        }
    }

    @include breakpoint.break-under(tablet) {
        .visual {
            margin-bottom: breakpoint.xdMbToRate(size.$try-visual-marginButton-mb);
        }
        h3 {
            font-size: breakpoint.xdMbToRate(size.$try-h3-fontsize-mb);
        }
    }
    @include breakpoint.break-over(tablet) {
        .visual {
            margin-bottom: breakpoint.xdPcToRate(size.$try-visual-marginButton-pc);
        }
        h3 {
            font-size: breakpoint.xdPcToRate(size.$try-h3-fontsize-pc);
        }
    }
}