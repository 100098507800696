@use '../utils/breakpoint';
@use '../utils/size';
@use '../utils/text';
@use '../utils/color';

.system {
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100% auto;
    &--logo,
    .pricelist {
        margin-left: auto;
        margin-right: auto;
        img {
            width: 100%;
            height: auto;
        }
    }
    h3 {
        font-weight: normal;
        color: color.$brand;
        @include text.fontSerif();
    }
    .timeprices {
        img {
            height: auto;
        }
    }
    .change-and-cancel {
        p {
            text-align: left;
            @include text.fontSerif();
        }
    }

    .drink {
        &--content {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }
        &-image {
            img {
                width: 100%;
                height: auto;
            }
        }
        &-list {
            dl {
                display: flex;
                flex-wrap: wrap;
                text-align: left;
            }
        }
    }

    .other {
        &-list {
            margin: 0 auto;
            dl {
                display: flex;
                width: 100%;
                position: relative;
                line-height: 1;
                align-items: center;
                justify-content: space-between;
                &::before {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 1px;
                    background: #707070;
                    position: absolute;
                    left: 0;
                    top: .5em;
                    z-index: -1;
                }
            }
            dt,
            dd {
                background: #000;
            }
        }
    }

    .benefit {
        &--image {
            margin-left: auto;
            margin-right: auto;
            img {
                width: 100%;
                height: auto;
            }
        }
        &--text {
            p {
                @include text.fontSerif();
            }
        }
    }

    .first {
        font-weight: bold;
        line-height: 1;
    }

    @include breakpoint.break-under(tablet) {
        background-image: url(/./assets/images/system/mv-mb.png);
        padding-top: breakpoint.xdMbToRate(size.$system-spacer-t-mb);
        &--logo {
            width: breakpoint.xdMbToRate(size.$system-logo-width-mb);
            margin-bottom: breakpoint.xdMbToRate(size.$system-logo-marginBottom-mb);
        }
        h3 {
            font-size: breakpoint.xdMbToRate(size.$system-h3-fontsize-mb);
            @include text.letterSpacing(size.$system-h3-letterspace-mb);
            @include text.lineHeight(size.$system-h3-fontsize-mb, size.$system-h3-fontsize-pc);
            margin-bottom: breakpoint.xdMbToRate(size.$system-h3-marginBottom-mb);
        }
        .pricelist {
            width: breakpoint.xdMbToRate(size.$system-pricelist-width-mb);
        }
        .timeprices {
            .pc {
                display: none;
            }
            .mb {
                .timeprice {
                    margin-left: auto;
                    margin-right: auto;
                    width: breakpoint.xdMbToRate(size.$system-timeprices-image-width-mb);
                    img {
                        width: 100%;
                        height: auto;
                    }
                    h4 {
                        font-size: breakpoint.xdMbToRate(size.$system-timeprice-title-fontsize-mb);
                        @include text.lineHeight(size.$system-timeprice-title-fontsize-mb, size.$system-timeprice-title-lineheight-mb);
                        @include text.fontSerif();
                        @include text.letterSpacing(size.$system-timeprice-title-letterspace-mb);
                        color: color.$brand;
                        margin-bottom: breakpoint.xdMbToRate(size.$system-timeprice-title-marginBottom-mb);
                    }
                    &__main {
                        margin-bottom: breakpoint.xdMbToRate(size.$system-timeprice-main-marginBottom-mb);
                    }
                    &__online {
                        margin-bottom: breakpoint.xdMbToRate(size.$system-timeprice-online-marginBottom-mb);
                    }
                    &__group {
                        margin-bottom: breakpoint.xdMbToRate(size.$system-timeprice-group-marginBottom-mb);
                    }
                }
                .mv {
                    width: 100%;
                    margin-bottom: breakpoint.xdMbToRate(size.$system-timeprice-mv-marginBottom-mb);
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
        .change-and-cancel {
            margin-left: breakpoint.xdMbToRate(size.$system-cac-spacer-l-mb);
            margin-right: breakpoint.xdMbToRate(size.$system-cac-spacer-r-mb);
            margin-bottom: breakpoint.xdMbToRate(size.$system-cac-marginBottom-mb);
            p {
                font-size: breakpoint.xdMbToRate(size.$system-cac-text-fontsize-mb);
                @include text.lineHeight(size.$system-cac-text-fontsize-mb, size.$system-cac-text-lineheight-mb);
                white-space: nowrap;
            }
        }
        .visual {
            display: none;
        }

        .menu-title {
            font-size: breakpoint.xdMbToRate(size.$system-menu-title-fontsize-mb);
            @include text.lineHeight(size.$system-menu-title-fontsize-mb, size.$system-menu-title-lineheight-mb);
        }

        .drink {
            margin-bottom: breakpoint.xdMbToRate(size.$system-drink-marginBottom-mb);
            .menu-title {
                margin-bottom: breakpoint.xdMbToRate(size.$system-drink-title-marginBottom-mb);
            }
            &-image {
                width: breakpoint.xdMbToRate(size.$system-drink-image-width-mb);
            }
            &-list {
                font-size: breakpoint.xdMbToRate(size.$system-drink-list-fontsize-mb);
                padding-left: breakpoint.xdMbToRate(size.$system-drink-list-dd-spacer-l-mb);
                dl {
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: breakpoint.xdMbToRate(size.$system-drink-list-marginBottom-mb);
                    line-height: 1;
                    &:nth-last-child(1) {
                        margin-bottom: 0;
                    }
                }
                dt {
                    flex: 1;
                }
                dd {
                    text-align: center;
                    width: 4em;
                }
            }
        }

        .other {
            margin-bottom: breakpoint.xdMbToRate(size.$system-other-marginBottom-mb);
            &-list {
                font-size: breakpoint.xdMbToRate(size.$system-other-list-fontsize-mb);
                @include text.lineHeight(size.$system-other-list-fontsize-mb, size.$system-other-list-lineheight-mb);
                width: breakpoint.xdMbToRate(size.$system-other-list-width-mb);
            }
            dl {
                margin-bottom: breakpoint.xdMbToRate(size.$system-other-list-marginBottom-mb);
            }
            dt {
                padding-right: breakpoint.xdMbToRate(size.$system-other-list-text-spacing-x-mb);
            }
            dd {
                padding-left: breakpoint.xdMbToRate(size.$system-other-list-text-spacing-x-mb);
            }
        }

        .benefit {
            margin-bottom: breakpoint.xdMbToRate(size.$system-benefit-marginBottom-mb);
            &--image{
                width: breakpoint.xdMbToRate(size.$system-benefit-image-width-mb);
            }
            &--text {
                p {
                    font-size: breakpoint.xdMbToRate(size.$system-benefit-text-fontsize-mb);
                    @include text.lineHeight(size.$system-benefit-text-fontsize-mb, size.$system-benefit-text-lineheight-mb);
                }
            }
        }

        .first {
            font-size: breakpoint.xdMbToRate(size.$system-first-text-fontsize-mb);
            margin-bottom: breakpoint.xdMbToRate(size.$system-benefit-marginBottom-mb);
        }
    }

    @include breakpoint.break-over(tablet) {
        background-image: url(/./assets/images/system/mv-pc.png);
        padding-top: breakpoint.xdPcToRate(size.$system-spacer-t-pc);
        &--logo {
            width: breakpoint.xdPcToRate(size.$system-logo-width-pc);
            margin-bottom: breakpoint.xdPcToRate(size.$system-logo-marginBottom-pc);
        }
        h3 {
            font-size: breakpoint.xdPcToRate(size.$system-h3-fontsize-pc);
            @include text.letterSpacing(size.$system-h3-letterspace-pc);
            @include text.lineHeight(size.$system-h3-fontsize-pc, size.$system-h3-lineheight-pc);
            margin-bottom: breakpoint.xdPcToRate(size.$system-h3-marginBottom-pc);
        }
        .pricelist {
            width: breakpoint.xdPcToRate(size.$system-pricelist-width-pc);
        }
        .timeprices {
            .pc {
                display: flex;
                flex-wrap: wrap;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: breakpoint.xdPcToRate(size.$system-timeprices-marginBottom-pc);
                width: breakpoint.xdPcToRate(size.$system-timeprices-width-pc);
                gap: 0;
                img {
                    flex-shrink: 1;
                    flex-grow: 1;
                    flex: 1;
                    width: calc(100% / 3);
                }
            }
            .mb {
                display: none;
            }
        }
        .change-and-cancel {
            margin-left: breakpoint.xdPcToRate(size.$system-cac-spacer-l-pc);
            margin-right: breakpoint.xdPcToRate(size.$system-cac-spacer-r-pc);
            margin-bottom: breakpoint.xdPcToRate(size.$system-cac-marginBottom-pc);
            p {
                font-size: breakpoint.xdPcToRate(size.$system-cac-text-fontsize-pc);
                @include text.lineHeight(size.$system-cac-text-fontsize-pc, size.$system-cac-text-lineheight-pc);
            }
        }
        .visual {
            margin-bottom: breakpoint.xdPcToRate(size.$system-visual-marginBottom-pc);
            img {
                width: 100%;
                height: auto;
            }
        }
        
        .menu-title {
            font-size: breakpoint.xdPcToRate(size.$system-menu-title-fontsize-pc);
            @include text.lineHeight(size.$system-menu-title-fontsize-pc, size.$system-menu-title-lineheight-pc);
        }

        .drink {
            margin-bottom: breakpoint.xdPcToRate(size.$system-drink-marginBottom-pc);
            .menu-title {
                margin-bottom: breakpoint.xdPcToRate(size.$system-drink-title-marginBottom-pc);
            }
            &--content {
                margin-left: breakpoint.xdPcToRate(size.$system-drink-content-spacer-x-pc);
                & > div {
                    line-height: 1;
                }
            }
            &-image {
                width: breakpoint.xdPcToRate(size.$system-drink-image-width-pc);
            }
            &-list {
                dl {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    font-size: breakpoint.xdPcToRate(size.$system-drink-list-fontsize-pc);
                    line-height: 1;
                    margin-bottom: breakpoint.xdPcToRate(size.$system-drink-list-marginBottom-pc);
                    &:nth-last-child(1) {
                        margin-bottom: 0;
                    }
                    dt {
                        padding-left: breakpoint.xdPcToRate(size.$system-drink-list-dd-spacer-l-pc);
                    }
                    dd {
                        text-align: center;
                        width: 4em;
                    }
                }
            }
        }

        .other {
            margin-bottom: breakpoint.xdPcToRate(size.$system-other-marginBottom-pc);
            &-list {
                font-size: breakpoint.xdPcToRate(size.$system-other-list-fontsize-pc);
                @include text.lineHeight(size.$system-other-list-fontsize-pc, size.$system-other-list-lineheight-pc);
                width: breakpoint.xdPcToRate(size.$system-other-list-width-pc);
                dl {
                    margin-bottom: breakpoint.xdPcToRate(size.$system-other-list-marginBottom-pc);
                }
                dt {
                    padding-right: breakpoint.xdPcToRate(size.$system-other-list-text-spacing-x-pc);
                }
                dd {
                    padding-left: breakpoint.xdPcToRate(size.$system-other-list-text-spacing-x-pc);
                }
            }
        }
        .benefit {
            margin-bottom: breakpoint.xdPcToRate(size.$system-benefit-marginBottom-pc);
            &--image {
                width: breakpoint.xdPcToRate(size.$system-benefit-image-width-pc);
                margin: 0 auto breakpoint.xdPcToRate(size.$system-benefit-image-marginBottom-pc);
            }
            &--text {
                p {
                    font-size: breakpoint.xdPcToRate(size.$system-benefit-text-fontsize-pc);
                    @include text.lineHeight(size.$system-benefit-text-fontsize-pc, size.$system-benefit-text-lineheight-pc);
                }
            }
        }

        .first {
            font-size: breakpoint.xdPcToRate(size.$system-first-text-fontsize-pc);
            margin-bottom: breakpoint.xdPcToRate(size.$system-benefit-marginBottom-pc);
        }
    }
}