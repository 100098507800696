@use '../utils/breakpoint';
@use '../utils/size';

.footer {
    display: block;
    margin: 0 auto;
    text-align: center;
    .company {
        margin-bottom: 1rem;
    }
    .copyright {
        line-height: 1;
    }
    
    @include breakpoint.break-under(tablet) {
        padding-top: breakpoint.xdMbToRate(size.$footer-spacer-t-mb);
        padding-bottom: breakpoint.xdMbToRate(size.$footer-spacer-b-mb);
        &--logo {
            display: none;
        }
        .company {
            font-size: breakpoint.xdMbToRate(size.$footer-company-fontsize-mb);
        }
        .copyright {
            font-size: breakpoint.xdMbToRate(size.$footer-copyright-fontsize-mb);
        }
    }

    @include breakpoint.break-over(tablet) {
        padding-top: breakpoint.xdPcToRate(size.$footer-spacer-t-pc);
        padding-bottom: breakpoint.xdPcToRate(size.$footer-spacer-b-pc);
        &--logo {
            width: breakpoint.xdPcToRate(size.$footer-logo-width-pc);
            margin: 0 auto breakpoint.xdPcToRate(size.$footer-logo-marginBottom-pc);
            img {
                width: 100%;
                height: auto;
            }
        }
    }
}