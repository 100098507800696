@use '../utils/zindex';
@use '../utils/breakpoint';
@use '../utils/size';

#header {
    background: #333;
    color: #999;
}
.header {
    height: 100vh;
    width: 100%;
    overflow: hidden;
    &--video {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        video {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            min-width: 100%;
            min-height: 100%;
            display: none;
        }
    }
}