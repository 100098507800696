@use '../utils/breakpoint';
@use '../utils/size';
@use '../utils/text';

.access {
    &--title {
        text-align: left;
        h2,
        h3,
        h4 {
            text-align: left;
            line-height: 1;
        }
    }
    &--info {
        text-align: left;
        p {
            @include text.fontSerif();
        }
    }

    @include breakpoint.break-under(tablet) {
        padding-top: breakpoint.xdMbToRate(size.$access-spacer-t-mb);
        &--logo {
            width: breakpoint.xdMbToRate(size.$access-logo-width-mb);
            margin: 0 auto breakpoint.xdMbToRate(size.$access-logo-marginBottom-mb);
            img {
                width: 100%;
                height: auto;
            }
        }
        &--title {
            margin-left: breakpoint.xdMbToRate(size.$access-title-spacer-l-mb);
            margin-bottom: breakpoint.xdMbToRate(size.$access-title-spacer-b-mb);
            h2 {
                font-size: breakpoint.xdMbToRate(size.$access-h2-fontsize-mb);
                margin-bottom: breakpoint.xdMbToRate(size.$access-h2-marginBottom-mb);
            }
            h3 {
                font-size: breakpoint.xdMbToRate(size.$access-h3-fontsize-mb);
                margin-bottom: breakpoint.xdMbToRate(size.$access-h3-marginBottom-mb);
            }
            h4 {
                font-size: breakpoint.xdMbToRate(size.$access-h4-fontsize-mb);
            }
        }
        &--info {
            margin-left: breakpoint.xdMbToRate(size.$access-info-marginLeft-mb);
            margin-bottom: breakpoint.xdMbToRate(size.$access-info-marginBottom-mb);
            p {
                font-size: breakpoint.xdMbToRate(size.$access-info-fontsize-mb);
                @include text.lineHeight(size.$access-info-fontsize-mb, size.$access-info-lineheight-mb);
                @include text.letterSpacing(size.$access-info-letterspace-mb);
            }
        }
    }

    @include breakpoint.break-over(tablet) {
        padding-top: breakpoint.xdPcToRate(size.$access-spacer-t-pc);
        &--logo {
            display: none;
        }
        &--title {
            margin-left: breakpoint.xdPcToRate(size.$access-title-spacer-l-pc);
            margin-bottom: breakpoint.xdPcToRate(size.$access-title-spacer-b-pc);
            h2 {
                font-size: breakpoint.xdPcToRate(size.$access-h2-fontsize-pc);
                margin-bottom: breakpoint.xdPcToRate(size.$access-h2-marginBottom-pc);
            }
            h3 {
                font-size: breakpoint.xdPcToRate(size.$access-h3-fontsize-pc);
                margin-bottom: breakpoint.xdPcToRate(size.$access-h3-marginBottom-pc);
            }
            h4 {
                font-size: breakpoint.xdPcToRate(size.$access-h4-fontsize-pc);
            }
        }
        &--info {
            margin-left: breakpoint.xdPcToRate(size.$access-info-marginLeft-pc);
            margin-bottom: breakpoint.xdPcToRate(size.$access-info-marginBottom-pc);
            p {
                font-size: breakpoint.xdPcToRate(size.$access-info-fontsize-pc);
                @include text.lineHeight(size.$access-info-fontsize-pc, size.$access-info-lineheight-pc);
                @include text.letterSpacing(size.$access-info-letterspace-pc);
            }
        }
    }
}