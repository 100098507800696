.crs {
    max-width: 70rem;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 17.5rem;

    &-logo {
        width: 8rem;
    }

    button {
        transition: opacity ease .5s;
        &:hover {
            opacity: .5;
        }
    }

    header {
        padding: 2rem 0;
        h2 {
            font-weight: normal;
        }
    }

    .ft {
        &-red {
            color: #c00;
        }
        &-blue {
            color: rgb(21, 2, 239);
        }
    }

    .list-steps {
        list-style: none;
        display: flex;
        align-items: stretch;
        position: relative;
        gap: 0;
        max-width: 40rem;
        margin: 0 auto 3.5rem;
        li {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1rem;
            flex: 1;
            .number,
            .check {
                background: #999;
                color: #fff;
                width: 5rem;
                height: 5rem;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .text {
                display: flex;
                align-items: center;
                flex: 1;
            }
            &.target {
                color: #999;
            }
            &.step {
                display: flex;
                flex: 1;
                width: 100%;
                height: 1px;
                align-items: center;
                justify-content: center;
                background: #999;
                margin-top: 2.4rem;
            }
        }
    }

    main {
        background: #fff;
        box-shadow: 0 0 .5 0 rgba(220, 220, 220, .8);
        padding: 1.5rem;
    }
    .steps {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        gap: 2.5rem;
        border-bottom: solid 1px #ccc;
        padding-bottom: 1.5rem;
        .step {
            width: 6rem;
            height: 6rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border: solid 3px #ccc;
            border-radius: 50%;
            .current {
                font-size: 3rem;
            }
            .max {
                color: #999;
                display: inline-block;
                padding-top: .5rem;
                &::before {
                    content: "/";
                }
            }
        }
        h3 {
            font-size: 2.5rem;
            font-weight: normal;
        }
    }

    .input-wrap {
        text-align: left;
        padding: 1.5rem;
        &.confirmation {
            dt {
                font-weight: bold;
                margin-bottom: 1.25rem;
            }
            dd {
                margin: 0 0 2.5rem 1.25rem;
            }
        }
    }
    .buttons-column {
        &.confirmation {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            gap: 1rem;
            max-width: 300px;
            margin: 0 auto;
            button {
                cursor: pointer;
                border: none;
                padding: 1.25rem 0;
                width: 100%;
                box-sizing: border-box;
                border-radius: .5rem;
                font-size: 1.4rem;
                &.primary {
                    background: #000;
                    color: #fff;
                }
                &.cancel {
                    background: #efefef;
                }
            }
        }
    }

    .menu-item {
        margin: 0 auto 2.5rem;
        label {
            display: inline-block;
            border: solid 1px #999;
            padding: 1rem 1.5rem;
            border-radius: .5rem;
            &:hover {
                background: #999;
            }
        }
        input {
            display: none;
            &:checked {
                & + label {
                    background: #999;
                    color: #fff;
                }
            }
        }
    }


    .backdrop-loading {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        left: 0;
        top: 0;
        background: rgba(255, 255, 255, .1);
        backdrop-filter: blur(1rem);
    }

    .prev-next {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 50rem;
        margin: 0 auto;
        button {
            background: #ccc;
            border-radius: .5rem;
            padding: .5rem;
            border: none;
            cursor: pointer;
        }
    }

    .calendar {
        position: relative;
        max-width: 50rem;
        margin: 0 auto;
        &-header {
            position: sticky;
            top: 0;
            left: 0;
            display: flex;
            flex-direction: row;
            & > div {
                flex: 1;
            }
        }
        &-body {
            display: flex;
            flex-direction: row;
            gap: 1px;
            & > div {
                flex: 1;
                display: flex;
                flex-direction: column;
                gap: 1px;
                & > div {
                    height: 4rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    box-sizing: border-box;
                    font-size: 1.2rem;
                    button {
                        border: none;
                        padding: .5rem 0;
                        height: 100%;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        font-size: 2rem;
                        &:hover {
                            background: #fff;
                        }
                        &:disabled {
                            cursor: default;
                        }
                    }
                }
            }
        }
        &-date {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .day {
                font-size: 1.2rem;
            }
            .week {
                font-size: 1rem;
            }
        }
    }

    .user-info-form {
        dt {
            font-weight: bold;
            padding: 1rem 0;
        }
        dd {
            margin-bottom: 1rem;
        }
        input {
            &[type="text"],
            &[type="email"],
            &[type="tel"] {
                padding: 1.25rem;
                border: solid 1px #999;
                box-sizing: border-box;
                width: 100%;
                border-radius: .75rem;
                &::placeholder {
                    color: #999;
                }
            }
        }
        textarea {
            padding: 1.25rem;
            border: solid 1px #999;
            box-sizing: border-box;
            width: 100%;
            border-radius: .75rem;
            height: 10rem;
            &::placeholder {
                color: #999;
            }
        }
    }

    .isRequire {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: .25rem 1rem;
        border-radius: .5rem;
        color: #fff;
        font-size: 1.2rem;
        margin-left: 1em;
        &.free {
            background: #999;
        }
        &.require {
            background: #c00;
        }
    }

    .inputError {
        color: #c00;
        font-weight: bold;
    }

    .buttons {
        &-column {
            display: flex;
            flex-direction: column;
        }
    }

    .bottom-status {
        background: #fff;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        box-shadow: 0 -5px 5px 0 rgba(220, 220, 220, .8);
        padding: 2.5rem  1.5rem;
        box-sizing: border-box;
        &-container {
            max-width: 70rem;
            width: 100%;
            gap: 1.5rem;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            margin: 0 auto;
        }
        &__left,
        &__right {
            flex: 1;
            max-width: calc(50% - 1.5rem / 2);
            text-align: left;
        }
        &__right {
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
        }
        .btn {
            border: none;
            font-size: 1.4rem;
            cursor: pointer;
            padding: 1.5rem 0;
            border-radius: .5rem;
            &.cancel {
                background: #efefef;
                color: #000;
            }
            &.primary {
                background: #000;
                color: #fff;
            }
        }
    }

    .fin {
        h2 {
            padding: 1.5rem 0;
        }
    }
}