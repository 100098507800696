@use '../utils/breakpoint';
@use '../utils/size';
@use '../utils/zindex';

.logo {
    position: fixed;
    min-width: 80px;
    left: 10px;
    top: 10px;
    img {
        width: 100%;
        height: auto;
    }
    @include zindex.get(header-logo);
    @include breakpoint.break-over(sm) {
        width: breakpoint.xdMbToSm(size.$movie-logo-width-mb);
        left: 15px;
        top: 15px;
    }
    @include breakpoint.break-over(tablet) {
        width: breakpoint.xdMbToTablet(size.$movie-logo-width-mb);
        left: 20px;
        top: 20px;
    }
    @include breakpoint.break-over(pc) {
        width: breakpoint.xdPcToPc(size.$movie-logo-width-pc);
        left: breakpoint.xdPcToPc(size.$movie-logo-left-pc);
        top: breakpoint.xdPcToPc(size.$movie-logo-top-pc);
    }
    @include breakpoint.break-over(wide) {
        width: breakpoint.xdPcToWide(size.$movie-logo-width-pc);
        left: breakpoint.xdPcToWide(size.$movie-logo-left-pc);
        top: breakpoint.xdPcToWide(size.$movie-logo-top-pc);
    }
    @include breakpoint.break-over(full) {
        width: breakpoint.xdPcToFull(size.$movie-logo-width-pc);
        left: breakpoint.xdPcToFull(size.$movie-logo-left-pc);
        top: breakpoint.xdPcToFull(size.$movie-logo-top-pc);
    }
}