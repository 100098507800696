@use 'sass:math';
@use '../utils/color';

/* font-family */
@mixin fontSerif {
    font-family: YuMincho, 'Yu Mincho', serif;
}
@mixin fontSansSerif {
    font-family: YuGothic, 'Yu Gothic', sans-serif;
}
@mixin fontYuGothicBold {
    font-family: 'YuGothic Bold', 'YuGo-Bold', 'Yu Gothic', sans-serif;
}
@mixin fontYuMinchoMedium {
    font-family: 'YuMin-Medium', 'YuMincho Medium', 'Yu Mincho';
}

/* line-height */
@mixin lineHeight($font, $lineheight) {
    line-height: math.div($lineheight, $font);
}

/* letter-spacing */
@mixin letterSpacing($size) {
    letter-spacing: math.div($size, 1000) * 1em;
}

/* color */
@mixin textYellow {
    color: color.$text-yellow;
}

/* selector */
.font-serif {
    @include fontSerif;
}

.font-sansserif {
    @include fontSansSerif;
}

.ft-yellow {
    @include textYellow;
}
