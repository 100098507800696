@use 'sass:math';

$xdMb: 1080px;
$xdPc: 1920px;
$breakpoints: (
    "sm": 250,
    "tablet": 650,
    "pc": 800,
    "wide": 1280,
    "full": $xdPc,
);

// XDのPCサイズデザインから画面幅に対応するサイズをvwで取得
@function xdPcToRate($size) {
    @return math.div(calc(100vw * $size), $xdPc);
}

// XDのMSサイズデザインから画面幅に対応するサイズをvwで取得
@function xdMbToRate($size) {
    @return math.div(calc(100vw * $size), $xdMb);
}

// XDのPCサイズデザインからターゲットブレークポイントサイズに変換する
@function xdPcTo($target, $size) {
    @if map-has-key($breakpoints, $target) {
        @return math.div(($size * map-get($breakpoints, $target)), $xdPc) * 1px;
    } @else {
        @error "Your input #{$target} size is not defined in breakpoints. Please choose in #{map-keys($breakpoints)}";
    }
}

// XDのモバイルサイズデザインからターゲットブレークポイントサイズに変換する
@function xdMbTo($target, $size) {
    @if map-has-key($breakpoints, $target) {
        @return math.div(($size * map-get($breakpoints, $target)), $xdMb) * 1px;
    } @else {
        @error "Your input #{$target} size is not defined in breakpoints. Please choose in #{map-keys($breakpoints)}";
    }
}

// from PC
@function xdPcToSm($size) {
    @return xdPcTo(sm, $size);
}
@function xdPcToTablet($size) {
    @return xdPcTo(tablet, $size);
}
@function xdPcToPc($size) {
    @return xdPcTo(pc, $size);
}
@function xdPcToWide($size) {
    @return xdPcTo(wide, $size);
}
@function xdPcToFull($size) {
    @return xdPcto(full, $size);
}

// from Mobile
@function xdMbToSm($size) {
    @return xdMbTo(sm, $size);
}
@function xdMbToTablet($size) {
    @return xdMbTo(tablet, $size);
}
@function xdMbToPc($size) {
    @return xdMbTo(pc, $size);
}
@function xdMbToWide($size) {
    @return xdMbTo(wide, $size);
}
@function xdMbToFull($size) {
    @return xdMbTo(full, $size);
}

// breakpoints
@mixin break-over($point) {
    @if map-has-key($breakpoints, $point) {
        @media (min-width: #{inspect(map-get($breakpoints, $point))}px) {
            @content;
        }
    } @else {
        @error "This breakpoint #{$point} is not defined in breakpoints. Please choose breakpoint in #{map-keys($breakpoints)}";
    }
}
@mixin break-under($point) {
    @if map-has-key($breakpoints, $point) {
        @media (max-width: #{inspect(map-get($breakpoints, $point) - 1)}px) {
            @content;
        }
    } @else {
        @error "This breakpoint #{$point} is not defined in breakpoints. Please choose breakpoint is #{map-keys($breakpoints)}";
    }
}
