@use '../utils/breakpoint';
@use '../utils/size';
@use '../utils/text';

.about {
    .mv {
        margin-left: auto;
        margin-right: 0;
        text-align: right;
        img {
            width: 100%;
            height: auto;
        }
    }
    .message {
        &--logo {
            text-align: left;
            img {
                height: auto;
            }
        }
        &--text {
            text-align: left;
            @include text.fontSerif();
        }
    }
    .training {
        &--text {
            text-align: left;
        }
    }
    .catchcopy {
        overflow: hidden;
    }

    @include breakpoint.break-under(tablet) {
        padding-top: breakpoint.xdMbToRate(size.$about-spacer-t-mb);
        .mv {
            margin-bottom: breakpoint.xdMbToRate(size.$about-mv-marginBottom-mb);
            width: breakpoint.xdMbToRate(size.$about-mv-width-mb);
        }
        .message {
            margin-bottom: breakpoint.xdMbToRate(size.$about-msg-marginBottom-mb);
            &--logo {
                &__mb {
                    width: breakpoint.xdMbToRate(size.$about-msg-logo-width-mb);
                    margin-left: breakpoint.xdMbToRate(size.$about-msg-logo-marginLeft-mb);
                    margin-bottom: breakpoint.xdMbToRate(size.$about-msg-logo-marginBottom-mb);
                }
                &__pc {
                    display: none;
                }
            }
            &--text {
                margin-left: breakpoint.xdMbToRate(size.$about-msg-text-spacer-l-mb);
                p {
                    margin-top: -0.7 * size.$about-msg-text-fontsiez-mb;
                    font-size: breakpoint.xdMbToRate(size.$about-msg-text-fontsiez-mb);
                    @include text.lineHeight(size.$about-msg-text-fontsiez-mb, size.$about-msg-text-lineheight-mb);
                }
            }
        }
        .sns {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: column;
            gap: breakpoint.xdMbToRate(size.$about-sns-gap-mb);
            margin-bottom: breakpoint.xdMbToRate(size.$about-sns-marginBottom-mb);
            margin-left: 15px;
            margin-right: 15px;
        }
        .training {
            margin-bottom: breakpoint.xdMbToRate(size.$about-training-marginBottom-mb);
            &--image {
                margin: 0 auto breakpoint.xdMbToRate(size.$about-training-image-marginBottom-mb);
                &__pc {
                    display: none;
                }
                &__mb {
                    width: 100%;
                    height: auto;
                }
            }
            &--text {
                margin-left: breakpoint.xdMbToRate(size.$about-training-text-spacer-l-mb);
                margin-right: breakpoint.xdMbToRate(size.$about-training-text-spacer-r-mb);
                p {
                    font-size: breakpoint.xdMbToRate(size.$about-training-text-fontsize-mb);
                    @include text.lineHeight(size.$about-training-text-fontsize-mb, size.$about-training-text-lineheight-mb);
                }
            }
        }
        .catchcopy {
            &__pc {
                display: none;
            }
            &__mb {
                margin-right: breakpoint.xdMbToRate(size.$about-catchcopy-right-mb);
                width: breakpoint.xdMbToRate(size.$about-catchcopy-width-mb);
                height: auto;
            }
        }
    }

    @include breakpoint.break-over(tablet) {
        padding-top: breakpoint.xdPcToRate(size.$about-spacer-t-pc);
        .mv {
            margin-bottom: breakpoint.xdPcToRate(size.$about-mv-marginBottom-pc);
            width: breakpoint.xdPcToRate(size.$about-mv-width-pc);
        }
        .message {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-start;
            margin-left: 0;
            margin-right: 0;
            margin-bottom: breakpoint.xdPcToRate(size.$about-msg-marginBottom-pc);
            padding-left: breakpoint.xdPcToRate(size.$about-msg-spacer-x-pc);
            &--logo {
                width: breakpoint.xdPcToRate(size.$about-msg-left-width-pc);
                &__mb {
                    display: none;
                }
                &__pc {
                    width: breakpoint.xdPcToRate(size.$about-msg-logo-width-pc);
                }
            }
            &--text {
                p {
                    font-size: breakpoint.xdMbToRate(size.$about-msg-text-fontsize-pc );
                    @include text.lineHeight(size.$about-msg-text-fontsize-pc, size.$about-msg-text-lineheight-pc);
                }
            }
        }
        .sns {
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: breakpoint.xdPcToRate(size.$about-sns-marginBottom-pc);
            width: breakpoint.xdPcToRate(size.$about-sns-width-pc);
            gap: breakpoint.xdPcToRate(size.$about-sns-gap-pc);
            &--instagram {
                flex: 1;
                flex-grow: 1;
                flex-shrink: 1;
                width: 50%;
            }
            &--tiktok {
                flex: 1;
                flex-grow: 1;
                flex-shrink: 1;
                width: 50%;
                display: flex;
                align-items: stretch;
                .tiktok-embed {
                    margin-top: 0 !important;
                    iframe {
                        height: 120% !important;
                        box-sizing: border-box;
                    }
                }
            }
        }
        .training {
            margin-bottom: breakpoint.xdPcToRate(size.$about-training-marginBottom-pc);
            &--image {
                margin-bottom: breakpoint.xdPcToRate(size.$about-training-image-marginBottom-pc);
                &__mb {
                    display: none;
                }
                &__pc {
                    width: breakpoint.xdPcToRate(size.$about-training-image-width-pc);
                    height: auto;
                }
            }
            &--text {
                margin-left: breakpoint.xdPcToRate(size.$about-training-text-spacer-l-pc);
                margin-right: breakpoint.xdPcToRate(size.$about-training-text-spacer-r-pc);
                p {
                    font-size: breakpoint.xdPcToRate(size.$about-training-text-fontsize-pc);
                    @include text.lineHeight(size.$about-training-text-fontsize-pc, size.$about-training-text-lineheight-pc);
                }
            }
        }
        .catchcopy {
            &__pc {
                width: breakpoint.xdPcToRate(size.$about-catchcopy-width-pc);
                height: auto;
                margin-right: breakpoint.xdPcToRate(size.$about-catchcopy-right-pc);
            }
            &__mb {
                display: none;
            }
        }
    }
}