@use '../utils/breakpoint';
@use '../utils/size';
@use '../utils/text';
@use '../utils/color';

.speciality {
    .purposeimg {
        margin: 0 auto;
        img {
            width: 100%;
            height: auto;
        }
    }
    .purpose {
        font-weight: bold;
    }
    .wantsimg {
        margin: 0 auto;
        img {
            width: 100%;
            height: auto;
        }
    }
    .profile {
        background-image: url(/./assets/images/speciality/profile-bg.png);
        background-position-x: center;
        background-repeat: no-repeat;
        background-size: cover;
        &--wrap {
            text-align: left;
            background-image: url(/./assets/images/speciality/profile-hashimoto.png);
            background-repeat: no-repeat;
            background-size: auto 100%;
            background-position-y: bottom;
            background-position-x: right;
            background-blend-mode: lighten;
        }
        .omakase {
            line-height: 1;
            font-weight: bold;
            color: color.$text-yellow-omakase;
        }
        &-container {
            font-weight: bold;
            text-shadow: 0 4px 6px rgba(0, 0, 0, .16);
        }
        .aword {
            color: color.$text-yellow-aword;
        }
        .incharge {
            line-height: 1;
        }
        .name {
            line-height: 1;
        }
        .link-to-instagram {
            border: none;
            background: transparent;
            cursor: pointer;
        }
    }

    @include breakpoint.break-under(tablet) {
        .section--container {
            padding-top: breakpoint.xdMbToRate(size.$speciality-spacer-t-mb);
        }
        .purposeimg {
            width: breakpoint.xdMbToRate(size.$speciality-purposeimg-width-mb);
        }
        .textspace {
            background: url(/./assets/images/speciality/bg-mb.png) no-repeat center center;
            background-size: cover;
            padding-top: breakpoint.xdMbToRate(size.$speciality-textspace-spacer-t-mb);
        }
        .purpose {
            margin-bottom: breakpoint.xdMbToRate(size.$speciality-purpose-marginBottom-mb);
            font-size: breakpoint.xdMbToRate(size.$speciality-purpose-fontsize-mb);
            @include text.lineHeight(size.$speciality-purpose-fontsize-mb, size.$speciality-purpose-lineheight-mb);
        }
        .wantsimg {
            width: breakpoint.xdMbToRate(size.$speciality-wants-img-width-mb);
        }
        .profile {
            background-position-y: breakpoint.xdMbToRate(size.$speciality-bg-top-mb);
            &--wrap {
                padding-top: breakpoint.xdMbToRate(size.$speciality-profile-spacer-top-mb);
                margin-left: breakpoint.xdMbToRate(size.$speciality-wrap-spacer-l-mb);
            }
            .omakase {
                font-size: breakpoint.xdMbToRate(size.$speciality-omakase-fontsize-mb);
                margin-bottom: breakpoint.xdMbToRate(size.$speciality-omakase-marginBottom-mb);
            }
            &-container {
                padding-top: breakpoint.xdMbToRate(size.$speciality-container-spacer-t-mb);
                padding-bottom: breakpoint.xdMbToRate(size.$speciality-container-spacer-b-mb);
            }
            &-catch {
                font-size: breakpoint.xdMbToRate(size.$speciality-profile-catch-fontsize-mb);
                margin-bottom: breakpoint.xdMbToRate(size.$speciality-profile-catch-marginBottom-mb);
                .line {
                    display: block;
                    margin-bottom: breakpoint.xdMbToRate(size.$speciality-profile-catch-textSpacer-mb);
                    &:nth-last-child(1) {
                        margin-bottom: 0;
                    }
                }
            }
            .aword {
                margin-bottom: breakpoint.xdMbToRate(size.$speciality-profile-aword-marginBottom-mb);
                font-size: breakpoint.xdMbToRate(size.$speciality-profile-aword-fontsize-mb);
                @include text.lineHeight(size.$speciality-profile-aword-fontsize-mb, size.$speciality-profile-aword-lineheight-mb);
            }
            .incharge {
                margin-bottom: breakpoint.xdMbToRate(size.$speciality-profile-incharge-marginBottom-mb);
                font-size: breakpoint.xdMbToRate(size.$speciality-profile-incharge-fontsize-mb);
            }
            .name {
                margin-bottom: breakpoint.xdMbToRate(size.$speciality-profile-name-marginBottom-mb);
                font-size: breakpoint.xdMbToRate(size.$speciality-profile-name-fontsize-mb);
            }
            .link-to-instagram {
                img {
                    width: breakpoint.xdMbToRate(size.$speciality-profile-instagram-icon-width-mb);
                    height: auto;
                }
            }
        }
    }
    @include breakpoint.break-over(tablet) {
        .section--container {
            padding-top: breakpoint.xdPcToRate(size.$speciality-spacer-t-pc);
        }
        .purposeimg {
            width: breakpoint.xdPcToRate(size.$speciality-purposeimg-width-pc);
        }
        .textspace {
            background: url(/./assets/images/speciality/bg-pc.png) no-repeat center center;
            background-size: cover;
            padding-top: breakpoint.xdPcToRate(size.$speciality-textspace-spacer-t-pc);
        }
        .purpose {
            margin-bottom: breakpoint.xdPcToRate(size.$speciality-purpose-marginBottom-pc);
            font-size: breakpoint.xdPcToRate(size.$speciality-purpose-fontsize-pc);
            @include text.lineHeight(size.$speciality-purpose-fontsize-pc, size.$speciality-purpose-lineheight-pc);
        }
        .wantsimg {
            width: breakpoint.xdPcToRate(size.$speciality-wants-img-width-pc);
        }
        .profile {
            background-position-y: breakpoint.xdPcToRate(size.$speciality-bg-top-pc);
            &--wrap {
                padding-top: breakpoint.xdPcToRate(size.$speciality-profile-spacer-top-pc);
                margin-left: breakpoint.xdPcToRate(size.$speciality-wrap-spacer-l-pc);
                margin-right: breakpoint.xdPcToRate(size.$speciality-wrap-spacer-r-pc);
            }
            .omakase {
                font-size: breakpoint.xdPcToRate(size.$speciality-omakase-fontsize-pc);
                margin-bottom: breakpoint.xdPcToRate(size.$speciality-omakase-marginBottom-pc);
            }
            &-container {
                padding-top: breakpoint.xdPcToRate(size.$speciality-container-spacer-t-pc);
                padding-bottom: breakpoint.xdPcToRate(size.$speciality-container-spacer-b-pc);
            }
            &-catch {
                font-size: breakpoint.xdPcToRate(size.$speciality-profile-catch-fontsize-pc);
                margin-left: breakpoint.xdPcToRate(size.$speciality-profile-catch-marginLeft-pc);
                margin-bottom: breakpoint.xdPcToRate(size.$speciality-profile-catch-marginBottom-pc);
                line-height: 1;
            }
            .aword {
                margin-bottom: breakpoint.xdPcToRate(size.$speciality-profile-aword-marginBottom-pc);
                font-size: breakpoint.xdPcToRate(size.$speciality-profile-aword-fontsize-pc);
                @include text.lineHeight(size.$speciality-profile-aword-fontsize-pc, size.$speciality-profile-aword-lineheight-pc);
            }
            .incharge {
                margin-bottom: breakpoint.xdPcToRate(size.$speciality-profile-incharge-marginBottom-pc);
                font-size: breakpoint.xdPcToRate(size.$speciality-profile-incharge-fontsize-pc);
            }
            .name {
                margin-bottom: breakpoint.xdMbToRate(size.$speciality-profile-name-marginBottom-pc);
                font-size: breakpoint.xdMbToRate(size.$speciality-profile-name-fontsize-pc);
            }
            .link-to-instagram {
                img {
                    width: breakpoint.xdPcToRate(size.$speciality-profile-instagram-icon-width-pc);
                    height: auto;
                }
            }
        }
    }
}