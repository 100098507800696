$zindex: (
    'backdrop': 12000,
    'header-logo': 9999,
);

@mixin get($key) {
    @if map-has-key($zindex, $key) {
        z-index: map-get($zindex, $key);
    } @else {
        @error "Your requested key is not defined in zindex.";
    }
}