.admin {
    background: #fff;
    color: #000;
    button {
        cursor: pointer;
    }

    input {
        &[type="text"],
        &[type="email"],
        &[type="tel"],
        &[type="password"] {
            box-sizing: border-box;
            width: 100%;
            padding: .5rem;
        }
    }
    textarea {
        box-sizing: border-box;
        width: 100%;
        padding: .5rem;
        min-height: 10rem;
    }

    .buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        gap: 1.5rem;
        padding: 1.5rem 0;
    }

    button {
        &.primary {
            padding: 1rem 1.5rem;
            box-sizing: border-box;
            width: 100%;
            max-width: 20rem;
            background-color: #00a113;
            color: #fff;
            border: none;
            border-radius: .5rem;
        }
        &.danger {
            padding: 1rem 1.5rem;
            box-sizing: border-box;
            background-color: #c00;
            color: #fff;
            border: none;
            border-radius: .5rem;
            white-space: nowrap;
        }
        &.cancel {
            padding: 1rem 1.5rem;
            box-sizing: border-box;
            background-color: #999;
            color: #fff;
            border: none;
            border-radius: .5rem;
        }
    }

    .ft {
        &-error {
            color: #c00;
        }
        &-success {
            color: #00a113;
        }
    }

    .error {
        color: #c00;
    }

    &.header-global {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
        padding: 1.5rem;
        border-bottom: solid 1px #ccc;
    }
    &.admin-2col-layout {
        display: flex;
        flex-direction: row;
        gap: 1rem;
    }

    .sidebar {
        background: #efefef;
        box-sizing: border-box;
        width: 100%;
        max-width: 30rem;
        min-width: 25rem;
        padding: 1.5rem;
        &-header {
            display: none;
        }
        &-toggle {
            border: none;
            padding: .5rem;
            border-radius: .5rem;
        }
        &-block {
            margin-bottom: 2rem;
            h3 {
                font-size: 1.8rem;
                margin-bottom: 1rem;
            }
        }
        ul {
            margin-left: 2.5rem;
        }
    }

    table {
        &.list {
            border: none;
            border-collapse: collapse;
            border-spacing: 1px;
            font-size: 1.4rem;
            table-layout: fixed;
            th {
                background-color: #efefef;
            }
            th, td {
                padding: 1rem 1.5rem;
                white-space: nowrap; /* Prevents text from wrapping */
                overflow: hidden; /* Hides overflow */
                text-overflow: ellipsis; /* Displays ellipsis for overflowing text */
            }
        }
    }

    .card {
        background: #efefef;
        margin-bottom: 2.5rem;
        box-sizing: border-box;
        padding: 1.5rem;
        &-form-row {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 1rem;
        }
    }

    .data {
        &-view {
            margin-bottom: 2.5rem;
            width: 100%;
            min-width: 45rem;
        }
        &-item {
            margin-bottom: 1rem;
            font-size: 1.4rem;
            dt {
                font-weight: bold;
                background: #efefef;
                padding: 1rem;
            }
        }
    }

    .title-with-button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1.5rem;
    }
    
    .radio {
        &-list {
            list-style: none;
            display: flex;
            gap: 1rem;
        }
    }

    .hint {
        background: #efefef;
        color: #333;
        font-size: 1.2rem;
        padding: 1rem;
        border-radius: .5rem;
        border: solid 1px #ccc;
        margin: 1rem auto 2rem;
        &-list {
            list-style: none;
        }
    }

    /* login */
    &.center-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        min-height: 100vh;
        padding: 1rem;
        box-sizing: border-box;

        main {
            box-shadow: 0 .2rem 1rem 0 rgba(0, 0, 0, 0.1);
            border-radius: .5rem;
            margin: 1.5rem auto;
            width: 40rem;
            box-sizing: border-box;
            &.login {
                padding: 0 2.5rem 2.5rem;
            }
        }
    }
    .login {
        h1 {
            font-size: 2rem;
            line-height: 1;
            padding: 2.5rem;
        }
        p {
            text-align: left;
            margin-bottom: 1.5rem;
            label {
                display: block;
                font-size: 1.2rem;
            }
            input {
                &[type="text"],
                &[type="email"],
                &[type="password"] {
                    box-sizing: border-box;
                    width: 100%;
                    padding: .75rem;
                    border-radius: .5rem;
                    border: solid 1px #ccc;
                }
            }
        }
        &-form {
            &-content {
                margin-bottom: 1.5rem;
            }
        }
        &-button {
            box-sizing: border-box;
            width: 100%;
            padding: 2rem 1.5rem;
            border: none;
            background: #000;
            color: #fff;
            border-radius: .5rem;
            cursor: pointer;
            transition: background-color .5s ease-in-out;
            &:hover {
                background: #999;
            }
        }
        &-footer {
            display: flex;
            list-style: none;
            gap: 1rem;
            li {
                font-size: 1.2rem;
                &::after {
                    content: "|";
                    margin: 0 .5rem;
                }
                &:nth-last-child(1) {
                    &::after {
                        content: "";
                        margin: 0;
                    }
                }
            }
        }
    }

    .backdrop {
        background: rgba(0, 0, 0, .7);
        backdrop-filter: blur(.5rem);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        color: #ccc;
        &-container {
            background: #fff;
            color: #000;
            border-radius: 1rem;
            min-width: 25rem;
            width: 100%;
            max-width: 40rem;
            box-sizing: border-box;
            padding: 1.5rem;
            text-align: center;
            position: relative;
            .close {
                border: none;
                background: transparent;
                padding: 0;
                position: absolute;
                right: -2rem;
                top: -2rem;
                line-height: 1;
            }
        }
    }

    .loading {
        svg {
            animation: 1s linear loadingAnimation infinite;
        }
    }

    .reserve {
        &-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        &-date-item {
            h3 {
                background: #efefef;
                padding: .5rem;
                border-radius: .5rem;
            }
        }
        &-list {
            list-style: none;
            li {
                border-bottom: dashed 1px #ccc;
                &:nth-last-child(1) {
                    border-bottom: none;
                }
                & > a {
                    display: flex;
                    gap: 1rem;
                    text-decoration: none;
                    color: #000;
                    padding: 1rem;
                    &:hover {
                        background: #efefef;
                    }
                }
                .time {
                    display: flex;
                    width: 4em;
                    align-items: center;
                    justify-content: flex-end;
                }
                .name {
                    display: flex;
                    flex: 1;
                    align-items: center;
                    justify-content: flex-start;
                }
            }
        }
    }

    .main-content {
        padding: 1rem;
        flex: 1;
    }

    .datetime-controls {
        display: flex;
        align-items: center;
        gap: 1.5rem;
    }

    .datetime-table {
        width: 100%;
        display: flex;
        align-items: start;
        justify-content: start;
        border-top: solid 1px #ccc;
        border-left: solid 1px #ccc;
        box-sizing: border-box;
        font-size: 1.2rem;
        .time-title {
            width: 8rem;
            border-right: solid 1px #ccc;
        }
        .time-list {
            display: flex;
            overflow-x: scroll;
        }
        .column {
            display: flex;
            flex-direction: column;
            justify-content: start;
            align-items: stretch;
            border-right: solid 1px #ccc;
            text-align: center;
        }
        .cell {
            padding: 1.5rem;
            border-bottom: solid 1px #ccc;
            background: #fff;
        }
        .title {
            background: #efefef;
        }
        .datetime-cell {
            cursor: pointer;
            &:hover {
                background: #efefef;
            }
        }
        .date-cell {
            cursor: pointer;
            &:hover {
                background: #dadada;
            }
        }
        .reserved {
            background: #999;
        }
        .disable {
            background: #eeff00;
            &:hover {
                background: #eeff00;
            }
        }
    }
    .datetime-confirm {
        padding-top: 3rem;
        .actions {
            padding-top: 3rem;
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
        }
    }
    .stop-information {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
        margin-bottom: 2rem;
        &-item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 1rem;
            .color {
                width: 2.5rem;
                height: 2.5rem;
                border: solid 1px #ccc;
                &.yellow {
                    background: #eeff00;
                }
                &.gray {
                    background: #999;
                }
            }
        }
    }
}

@keyframes loadingAnimation {
    from { transform: rotate(0); }
    to { transform: rotate(360deg); }
}

@media (max-width: 40rem) {
}
@media (max-width: 60rem) {
    .admin {
        &.admin-2col-layout {
            flex-direction: column;
        }
        .sidebar {
            max-width: none;
            &-header {
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }
            &-content {
                display: none;
                &.open {
                    display: unset;
                }
            }
        }
        table {
            &.list {
                width: 100%;
            }
        }

        .data {
            &-view {
                min-width: unset;
            }
        }
    }
}