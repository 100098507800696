@use '../utils/breakpoint';
@use '../utils/size';
@use '../utils/text';
@use '../utils/color';
@use '../utils/zindex';

.form {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;

    .backdrop {
        display: none;
    }

    &.sending {
        .backdrop {
            width: 100%;
            height: 100%;
            position: fixed;
            left: 0;
            top: 0;
            background: rgba(10, 10, 10, .8);
            backdrop-filter: blur(3px);
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            @include zindex.get(backdrop);
            &--content {
                padding: 30px;
            }
            &--title {
                font-size: 2.4rem;
                font-weight: bold;
                margin-bottom: 10px;
            }
            &--message {
                font-size: 1.8rem;
                margin-bottom: 20px;
                white-space: pre-wrap;
            }
            button {
                border: none;
                border-radius: 5px;
                padding: 10px 25px;
                cursor: pointer;
            }
        }
    }

    .section--content {
        margin-left: auto;
        margin-right: auto;
    }

    &--title {
        font-weight: bold;
        line-height: 1;
    }

    &--element {
        input[type="text"],
        input[type="email"],
        input[type="tel"],
        textarea {
            border: none;
            background: #fff;
            box-sizing: border-box;
            width: 100%;
            padding: .5em;
        }
        dt {
            text-align: left;
            &.require {
                &::after{
                    content: "※必須";
                    color: color.$text-red;
                    margin-left: .5rem;
                }
            }
            &.freeform {
                &::after {
                    content: "※任意";
                    margin-left: .5rem;
                }
            }
        }
    }
    .buttons {
        text-align: left;
        .btn-send {
            padding: .25em 1em;
        }
    }

    @include breakpoint.break-under(tablet) {
        background-image: url(/./assets/images/form/bg-mb.png);
        padding-top: breakpoint.xdMbToRate(size.$form-spacer-t-mb);
        padding-bottom: breakpoint.xdMbToRate(size.$form-spacer-b-mb);
        .section--content {
            width: breakpoint.xdMbToRate(size.$form-width-mb);
        }
        &--title {
            font-size: breakpoint.xdMbToRate(size.$form-title-fontsize-mb);
            margin-bottom: breakpoint.xdMbToRate(size.$form-title-marginBottom-mb);
        }
        &--element {
            dl {
                margin-bottom: breakpoint.xdMbToRate(size.$form-element-marginBottom-mb);
                font-size: breakpoint.xdMbToRate(size.$form-label-fontsize-mb);
            }
        }
        textarea {
            height: breakpoint.xdMbToRate(size.$form-textarea-height-mb);
        }
    }

    @include breakpoint.break-over(tablet) {
        background-image: url(/./assets/images/form/bg-pc.png);
        padding-top: breakpoint.xdPcToRate(size.$form-spacer-t-pc);
        padding-bottom: breakpoint.xdPcToRate(size.$form-spacer-b-pc);
        .section--content {
            width: breakpoint.xdPcToRate(size.$form-width-pc);
        }
        &--title {
            font-size: breakpoint.xdPcToRate(size.$form-title-fontsize-pc);
            margin-bottom: breakpoint.xdPcToRate(size.$form-title-marginBottom-pc);
        }
        &--element {
            dl {
                margin-bottom: breakpoint.xdPcToRate(size.$form-element-marginBottom-pc);
                font-size: breakpoint.xdPcToRate(size.$form-label-fontsize-pc);
            }
        }
        textarea {
            height: breakpoint.xdPcToRate(size.$form-textarea-height-pc);
        }
    }
}