@use '../utils/breakpoint';
@use '../utils/size';
@use '../utils/text';

.contact {
    button {
        background: transparent;
        border: none;
        cursor: pointer;
        img {
            width: 100%;
            height: auto;
        }
    }

    .phone-for-enable-call {
        display: none;
    }
    .phone-for-disable-call {
        display: block;
    }
    .phone-call {
        display: inline-block;
        @include text.textYellow();
    }

    @include breakpoint.break-under(tablet) {
        background: url(/./assets/images/contact/bg-mb.png) no-repeat center center;
        background-size: cover;
        padding-top: breakpoint.xdMbToRate(size.$contact-spacer-t-mb);
        padding-bottom: breakpoint.xdMbToRate(size.$contact-spacer-b-mb);
        &--primary {
            button {
                width: breakpoint.xdMbToRate(size.$contact-primary-img-width-mb);
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
        .contact--phone {
            .title {
                font-size: breakpoint.xdMbToRate(size.$contact-phone-title-fontsize-mb);
                margin-bottom: breakpoint.xdMbToRate(size.$contact-phone-title-marginButton-mb);
            }
        }
        .phone-for-enable-call {
            margin-left: breakpoint.xdMbToRate(size.$contact-phone-spacer-l-mb);
            width: breakpoint.xdMbToRate(size.$contact-phone-img-width-mb);
        }
    }
    @include breakpoint.break-over(tablet) {
        background: url(/./assets/images/contact/bg-pc.png) no-repeat center center;
        background-size: cover;
        padding-top: breakpoint.xdPcToRate(size.$contact-spacer-t-pc);
        padding-bottom: breakpoint.xdPcToRate(size.$contact-spacer-b-pc);
        &--primary {
            display: flex;
            flex-wrap: wrap;
            gap: breakpoint.xdPcToRate(size.$contact-primary-gap-pc);
            margin: 0 auto breakpoint.xdPcToRate(size.$contact-primary-marginButtom-pc);
            width: breakpoint.xdPcToRate(size.$contact-primary-width-pc);
            button {
                flex: 1;
            }
        }
        .contact--phone {
            .title {
                font-size: breakpoint.xdPcToRate(size.$contact-phone-title-fontsize-pc);
                margin-bottom: breakpoint.xdPcToRate(size.$contact-phone-title-marginButton-pc);
            }
        }
        .phone-call {
            font-size: breakpoint.xdPcToRate(size.$contact-phone-fontsize-pc);
        }
    }
}